import { useEffect } from "react";

import Button from "components/Button";
import useCurrentUser from "hooks/useCurrentUser";
import useErrorLogger from "hooks/useErrorLogger";
import { ERROR_TYPES } from "utils/constants";
type CustomErrorProps = { title?: string; error: Error; reset: () => void };

const CustomErrorComponent = (props: CustomErrorProps) => {
  const { logError } = useErrorLogger();
  const currentUser = useCurrentUser();

  useEffect(() => {
    props.error && console.error(props.error);
    logError({
      error: props.error,
      source: "CustomErrorComponent",
      message: props.error.message || "Error in CustomErrorComponent",
      type: ERROR_TYPES.APP_ERROR,
      url: window.location.href,
      additionalInfo: {
        stack: props.error.stack,
        currentUser
      }
    });
  }, [props.error, logError, currentUser]);

  return (
    <div className="flex h-full w-full flex-row items-center justify-center px-6 py-5 align-middle">
      <span>Something went wrong! Please refresh the page or contact us.</span>
      <Button label="Reset error boundary" onClick={props.reset} />
    </div>
  );
};
export default CustomErrorComponent;
